import React from 'react';
import { EXTERNAL_PARTNER, PEACH } from './constants';
import HardNavigate from '../HardNavigate';
import useSession from '../../resources/session/read';
import index from '../../hooks/useRedirectUri';

const AuthenticatedRedirects = () => {
  const { data: session } = useSession();
  const redirectUri = index();

  if (session.role === EXTERNAL_PARTNER) {
    return <HardNavigate to={`/${process.env.PREFIX}/partners`} />;
  }

  if (session.servicingPlatform === PEACH) {
    return <HardNavigate to={process.env.PEACH_AUTH_URL} />;
  }

  if (redirectUri) {
    return <HardNavigate to={redirectUri} />;
  }

  return <HardNavigate to="/home" />;
};

export default AuthenticatedRedirects;
